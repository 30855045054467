class RightMenu {

    constructor() {
        this.selector = $('[data-toggle="right-menu"]');
        this.addEvent();
    }

    toggler(ev){
        let selectorName = $(ev.currentTarget).attr('data-target');
        let body = $('body')[0];

        if($(selectorName).hasClass('fixed-menu-open')){
            $(selectorName).removeClass('fixed-menu-open');
            body.onclick = null;
        } else {
            $(selectorName).addClass('fixed-menu-open');
            setTimeout(()=>{
                body.onclick = ()=> { this.toggler(ev) };
            }, 100);

        }
    }

    addEvent(){
        this.selector.click((ev)=> { this.toggler(ev)});
    }

}