class ScrollTo {

    constructor() {
        this.selector = $('[data-selector="go-to-top"]');
        this.addEvent();
    }

    addEvent(){
        this.selector.click((ev)=>{
            $('html,body').animate({ scrollTop: ($("body").offset().top) }, 1000);
            return false;
        });
    }

}
